import React, {createRef, FC, ReactElement, useEffect, useMemo, useState} from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {ChargedMoveWithCounts, loadRankingsDataAsync, MoveData, RankingData} from './lib/load_data_async';
import {impossible, MoveId, useEffectAsync} from './lib/common';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function getMoveName(move: MoveData): string {
    const maybeAsterisk = move.isRecommended ? '*' : '';
    return move.name + maybeAsterisk;
}

const TypeIcon: FC<{type: string}> = ({type}) => {
    const makeImagePath = (type: string) => {
        const capitalizedType = type[0].toUpperCase() + type.substring(1);
        return `/images/Icon_${capitalizedType}.webp`;
    };

    return <Box
        component={'img'}
        sx={{
            width: 32,
            height: 32,
        }}
        src={makeImagePath(type)}
    />;
};

const MoveCard: FC<{
    mode: 'normal' | 'residual';
    moveInfo:
        | {type: 'fast'; move: MoveData}
        | {type: 'charged'; move: ChargedMoveWithCounts}
}> = (props) => {
    const additionalData = (() => {
        switch (props.moveInfo.type) {
            case 'fast': return <Typography>Turns: {props.moveInfo.move.cooldown / 500}</Typography>;
            case 'charged': {
                let text: string;
                switch (props.mode) {
                    case 'normal': {
                        text = props.moveInfo.move.counts.join(' - ');
                        break;
                    }
                    case 'residual': {
                        const {move} = props.moveInfo;
                        text = [
                            String(move.defaultCount),
                            `(+${move.residualEnergyProduce}, -${move.residualEnergyConsume})`,
                        ].join(' ');
                        break;
                    }
                    default: impossible(props.mode);
                }
                return <Typography>Count: {text}</Typography>;
            }
            default: impossible(props.moveInfo);
        }
    })();

    return <Box width={500}>
        <Card>
            <Stack direction={'row'} alignItems={'center'} spacing={2} padding={1.5}>
                <Typography>{getMoveName(props.moveInfo.move)}</Typography>
                <TypeIcon type={props.moveInfo.move.type}/>
                {additionalData}
            </Stack>
        </Card>
    </Box>;
};

const RankingCard: FC<{
    mode: 'normal' | 'residual';
    data: RankingData;
}> = ({
    mode,
    data: {
        rank,
        speciesName,
        speciesTypes,
        moveSets,
        attack,
    },
}) => {
    const [fastMoveId, setFastMoveId] = useState<MoveId>(moveSets[0].fastMove.moveId);
    const handleClickTab = (event: React.SyntheticEvent, newValue: MoveId) => {
        setFastMoveId(newValue);
    };

    return <Card style={{backgroundColor: 'rgb(230, 230, 230)'}}>
        <Stack spacing={2} padding={2}>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Typography variant={'h4'}>{`${rank}. ${speciesName}`}</Typography>
                {speciesTypes.map(type => {
                    return <TypeIcon
                        key={type}
                        type={type}
                    />;
                })}
                <Typography>Attack = {attack}</Typography>
            </Stack>
            <TabContext value={fastMoveId}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleClickTab}>
                        {moveSets.map(moveSet => {
                            return <Tab
                                key={moveSet.fastMove.moveId}
                                value={moveSet.fastMove.moveId}
                                label={getMoveName(moveSet.fastMove)}
                            />;
                        })}
                    </TabList>
                </Box>
                {moveSets.map(moveSet => {
                    return <TabPanel key={moveSet.fastMove.moveId} value={moveSet.fastMove.moveId}>
                        <Stack spacing={2}>
                            <MoveCard mode={mode} moveInfo={{type: 'fast', move: moveSet.fastMove}}/>
                            {moveSet.chargedMoves.map(chargedMove => {
                                return <MoveCard
                                    key={chargedMove.moveId}
                                    mode={mode}
                                    moveInfo={{type: 'charged', move: chargedMove}}
                                />;
                            })}
                        </Stack>
                    </TabPanel>;
                })}
            </TabContext>
        </Stack>
    </Card>
};

enum TabId {
    littleLeagueRankings = 'littleLeagueRankings',
    greatLeagueRankings = 'greatLeagueRankings',
    ultraLeagueRankings = 'ultraLeagueRankings',
    masterLeagueRankings = 'masterLeagueRankings',
}

export const App: FC = () => {
    const [mode, setMode] = useState<'normal' | 'residual'>('normal');

    const [tabId, setTabId] = useState<TabId>(TabId.greatLeagueRankings);
    const handleChangeTabId = (event: React.SyntheticEvent, newValue: TabId) => {
        setTabId(newValue);
    };

    const [pokemonNameFilter, setPokemonNameFilter] = useState('');
    const handleChangePokemonNameFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPokemonNameFilter(event.target.value);
    };

    const pokemonNameFilterRef = createRef<HTMLInputElement>();
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'f' || event.key === 'F') {
                const ref = pokemonNameFilterRef.current;
                if (ref !== null && document.activeElement !== pokemonNameFilterRef.current) {
                    ref.focus();
                    ref.select();
                    event.preventDefault();
                }
            }
        };
        document.addEventListener('keypress', handleKeyPress);
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [pokemonNameFilterRef]);

    const [allRankingDatas, setAllRankingDatas] = useState<Array<RankingData> | null>(null);
    useEffectAsync(async () => {
        setAllRankingDatas(null);
        const league = (() => {
            switch (tabId) {
                case TabId.littleLeagueRankings: return 'little';
                case TabId.greatLeagueRankings: return 'great';
                case TabId.ultraLeagueRankings: return 'ultra';
                case TabId.masterLeagueRankings: return 'master';
                default: throw impossible(tabId);
            }
        })();
        const datas = await loadRankingsDataAsync(league);
        setAllRankingDatas(datas);
    }, [tabId]);

    const maxEntries = 10;
    const shownRankingDatas = useMemo(() => {
        if (allRankingDatas === null) return null;

        if (pokemonNameFilter === '') return allRankingDatas.slice(0, maxEntries);

        return allRankingDatas.filter(data => {
            return data.speciesName.toLowerCase().startsWith(pokemonNameFilter.toLowerCase());
        }).slice(0, maxEntries);
    }, [allRankingDatas, pokemonNameFilter]);

    const content: ReactElement = (() => {
        if (shownRankingDatas === null) {
            return <Typography>Loading...</Typography>;
        } else {
            return <Stack spacing={2}>
                {shownRankingDatas.map(ranking => {
                    return <RankingCard
                        key={ranking.speciesId}
                        mode={mode}
                        data={ranking}
                    />;
                })}
                <Typography>(At most {maxEntries} entries are shown.)</Typography>
            </Stack>;
        }
    })();

    return <Container>
        <Stack spacing={2} padding={2}>
            <Stack direction={'row'} spacing={1}>
                <Stack direction={'row'} spacing={1} flex={1}>
                    <TextField
                        sx={{width: 500}}
                        inputRef={pokemonNameFilterRef}
                        value={pokemonNameFilter}
                        onChange={handleChangePokemonNameFilter}
                        autoFocus
                        placeholder={`Filter pokemon by name (press 'f' to focus)`}
                        fullWidth
                    />
                </Stack>
                <ToggleButtonGroup
                    color="primary"
                    value={mode}
                    exclusive
                    onChange={(_event, val) => setMode(val)}
                >
                    <ToggleButton value={'normal'}>Counts</ToggleButton>
                    <ToggleButton value={'residual'}>Residual</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Tabs value={tabId} onChange={handleChangeTabId}>
                <Tab value={TabId.littleLeagueRankings} label={'Little'}/>
                <Tab value={TabId.greatLeagueRankings} label={'Great'}/>
                <Tab value={TabId.ultraLeagueRankings} label={'Ultra'}/>
                <Tab value={TabId.masterLeagueRankings} label={'Master'}/>
            </Tabs>
            {content}
        </Stack>
    </Container>;
}
