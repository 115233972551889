import {z} from 'zod';
import {DependencyList, useEffect} from 'react';

type LoadJsonAsyncFn<T> = (fileUrl: string) => Promise<T>;

export function makeLoadJsonAsyncFn<T>(schema: z.ZodSchema<T, any, unknown>): LoadJsonAsyncFn<T> {
    const jsonsByUrl = new Map<string, T>();

    return async (fileUrl: string) => {
        const maybeJson = jsonsByUrl.get(fileUrl);
        if (maybeJson !== undefined) {
            return maybeJson;
        }
        const response = await fetch(fileUrl);
        const contents = await response.json();
        const json = schema.parse(contents);

        // There's a race where two different load fns could set the same key.
        // But the data is static anyways, so...
        jsonsByUrl.set(fileUrl, json);

        return json;
    };
}

export type Integer = number;

export type SpeciesId = string;
export type MoveId = string;

function logErrorAndThrow(message: string, context?: unknown): Error {
    console.error(message, context);
    if (context === undefined) {
        throw new Error(message);
    }
    throw new Error(`${message}: ${context}`);
}

export function exactlyOne<T>(arr: Array<T>): T {
    if (arr.length !== 1) {
        throw logErrorAndThrow('Expected array to have exactly 1 element.', {arr});
    }
    return arr[0];
}

export function mapAdd<K, V>(m: Map<K, V>, k: K, v: V): void {
    if (m.has(k)) {
        throw logErrorAndThrow('Key already exists in map.', {m, k});
    }
    m.set(k, v);
}

export function mapGetOrThrow<K, V>(m: Map<K, V>, k: K): V {
    const v = m.get(k);
    if (v === undefined) {
        throw logErrorAndThrow('Key does not exist in map.', {m, k});
    }
    return v;
}

function forgetPromise(_promise: Promise<unknown>): void {}
function fireAndForget(fnAsync: () => Promise<unknown>): void {
    forgetPromise(fnAsync());
}

export function impossible(_: never): never {
    throw new Error('impossible according to types');
}

export function useEffectAsync(
    fnAsync: () => Promise<unknown>,
    deps?: DependencyList,
) {
    useEffect(() => {
        fireAndForget(async () => {
            await fnAsync();
        });
    }, deps);
}
