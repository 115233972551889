import {z} from 'zod';
import {makeLoadJsonAsyncFn} from './common';

export type PokemonJson = z.infer<typeof pokemonSchema>;
const pokemonSchema = z.object({
    speciesName: z.string(),
    speciesId: z.string(),
    types: z.array(z.string()).transform(types => {
        return types.filter(t => t !== 'none');
    }),
});

export const loadPokemonJsonsAsync = makeLoadJsonAsyncFn(z.array(pokemonSchema));
