import {z} from 'zod';
import {makeLoadJsonAsyncFn} from './common';

export type MoveJson = z.infer<typeof moveSchema>;
const moveSchema = z.object({
    moveId: z.string(),
    name: z.string(),
    type: z.string(),
    power: z.number().int(),
    energy: z.number().int(),
    energyGain: z.number().int(),
    cooldown: z.number().int(),

    // nullable because the move 'TRANSFORM' has no archetype
    archetype: z.string().nullable().default(null),
});

export const loadMoveJsonsAsync = makeLoadJsonAsyncFn(z.array(moveSchema));
