import {z} from 'zod';
import {makeLoadJsonAsyncFn} from './common';

const rankingMoveSchema = z.object({
    moveId: z.string(),
});

export type RankingJson = z.infer<typeof rankingSchema>;
const rankingSchema = z.object({
    speciesId: z.string(),
    speciesName: z.string(),
    score: z.number(),
    moves: z.object({
        fastMoves: z.array(rankingMoveSchema),
        chargedMoves: z.array(rankingMoveSchema),
    }),
    moveset: z.array(z.string()),
    stats: z.object({
        product: z.number(),
        atk: z.number(),
        def: z.number(),
        hp: z.number(),
    }),
});

const rankingsSchema = z.array(rankingSchema).transform(val => {
    const rankings = val.slice();
    rankings.sort((a, b) => {
        if (a.score > b.score) return -1;
        if (a.score < b.score) return 1;
        return 0;
    });
    return rankings;
});

export const loadRankingJsonsAsync = makeLoadJsonAsyncFn(rankingsSchema);
