import {loadPokemonJsonsAsync} from './load_pokemon_jsons_async';
import {loadMoveJsonsAsync, MoveJson} from './load_move_jsons_async';
import {Integer, mapGetOrThrow, MoveId, SpeciesId} from './common';
import {loadRankingJsonsAsync} from './load_ranking_jsons_async';
import assert from 'assert';

const jsonFileUrls = {
    pokemon: '/data/pokemon.json',
    rankings500: '/data/rankings-500.json',
    rankings1500: '/data/rankings-1500.json',
    rankings2500: '/data/rankings-2500.json',
    rankings10000: '/data/rankings-10000.json',
    moves: '/data/moves.json',
};

export type MoveData = MoveJson & {
    isRecommended: boolean;
}

export type ChargedMoveWithCounts = MoveData & {
    counts: Array<Integer>;
    defaultCount: Integer;
    residualEnergyProduce: Integer;
    residualEnergyConsume: Integer;
};

type MoveSet = {
    fastMove: MoveData;
    chargedMoves: Array<ChargedMoveWithCounts>;
};

export type RankingData = {
    rank: Integer;
    speciesId: SpeciesId;
    speciesName: string;
    speciesTypes: Array<string>;
    moveSets: Array<MoveSet>;
    attack: number;
};

type League = 'little' | 'great' | 'ultra' | 'master';

const rankingJsonsFileUrlByLeague: Record<League, string> = {
    little: jsonFileUrls.rankings500,
    great: jsonFileUrls.rankings1500,
    ultra: jsonFileUrls.rankings2500,
    master: jsonFileUrls.rankings10000,
};

export async function loadRankingsDataAsync(league: League): Promise<Array<RankingData>> {
    const pokemonJsons = await loadPokemonJsonsAsync(jsonFileUrls.pokemon);
    const moveJsons = await loadMoveJsonsAsync(jsonFileUrls.moves);

    const rankingJsonsFileUrl = rankingJsonsFileUrlByLeague[league];
    const rankingJsons = await loadRankingJsonsAsync(rankingJsonsFileUrl);

    const pokemonJsonsBySpeciesId = new Map(pokemonJsons.map(json => [json.speciesId, json]));

    const getMoveJson: ((moveId: MoveId) => MoveJson) = (() => {
        const jsonsByMoveId = new Map(moveJsons.map(json => [json.moveId, json]));
        return moveId => mapGetOrThrow(jsonsByMoveId, moveId);
    })();

    return rankingJsons
        .filter(rankingJson => {
            // Little league data has some pokemon ids with a "_xs" suffix for some reason.
            // e.g. "azumarill_xs"
            return pokemonJsonsBySpeciesId.get(rankingJson.speciesId) !== undefined;
        })
        .map((rankingJson, index) => {
            const recommendedMoveIds = new Set(rankingJson.moveset);

            const fastMoves: Array<MoveData> = rankingJson.moves.fastMoves.map(m => ({
                ...getMoveJson(m.moveId),
                isRecommended: recommendedMoveIds.has(m.moveId)
            }));
            const chargedMoves: Array<MoveData> = rankingJson.moves.chargedMoves.map(m => ({
                ...getMoveJson(m.moveId),
                isRecommended: recommendedMoveIds.has(m.moveId),
            }));

            const compareMoves = (m1: MoveData, m2: MoveData): number => {
                const m1Rank = m1.isRecommended ? 0 : 1;
                const m2Rank = m2.isRecommended ? 0 : 1;

                if (m1Rank < m2Rank) return -1;
                if (m1Rank > m2Rank) return 1;
                return 0;
            };
            fastMoves.sort(compareMoves);
            chargedMoves.sort(compareMoves);

            const moveSets: Array<MoveSet> = [];
            for (const fastMove of fastMoves) {
                moveSets.push(constructMoveSet(fastMove, chargedMoves));
            }

            const pokemonJson = pokemonJsonsBySpeciesId.get(rankingJson.speciesId);
            assert(pokemonJson !== undefined);

            return {
                rank: index + 1,
                speciesId: rankingJson.speciesId,
                speciesName: rankingJson.speciesName,
                speciesTypes: pokemonJson.types,
                moveSets,
                attack: rankingJson.stats.atk,
            };
        });
}

function constructMoveSet(fastMove: MoveData, chargedMoves: Array<MoveData>): MoveSet {
    return {
        fastMove,
        chargedMoves: chargedMoves.map(m => {
            const moveInfo = calculateChargedMoveInfo(fastMove.energyGain, m.energy);
            return {
                ...m,
                counts: calculateCounts(fastMove.energyGain, m.energy),
                defaultCount: moveInfo.defaultCount,
                residualEnergyProduce: moveInfo.residualEnergyProduce,
                residualEnergyConsume: moveInfo.residualEnergyConsume,
            };
        }),
    };
}

function calculateCounts(energyGain: Integer, energyCost: Integer): Array<Integer> {
    if (energyGain === 0) {
        // The move 'Transform' has 0 energy gain.
        return [];
    }

    const maxLoops = 500;
    let numLoops = 0;

    const counts = [];
    let currentCount = 0;
    let currentEnergy = 0;

    while (counts.length < 5) {
        if (currentEnergy >= energyCost) {
            counts.push(currentCount);
            currentCount = 0;
            currentEnergy -= energyCost;
        } else {
            currentCount += 1;
            currentEnergy += energyGain;
        }

        numLoops += 1;
        if (numLoops === maxLoops) {
            throw new Error(`Exceeded max loops: ${maxLoops}`);
        }
    }
    return counts;
}

function calculateChargedMoveInfo(energyGain: Integer, energyCost: Integer): {
    defaultCount: Integer;
    residualEnergyProduce: Integer;
    residualEnergyConsume: Integer;
} {
    const quotient = energyCost / energyGain;
    const quotientFloored = Math.floor(quotient);

    let defaultCount: Integer;
    let residualEnergyProduce: Integer;
    let residualEnergyConsume: Integer;
    if (quotient > quotientFloored) {
        defaultCount = quotientFloored + 1;
        residualEnergyProduce = energyGain * defaultCount - energyCost;
        residualEnergyConsume = energyCost - (energyGain * (defaultCount - 1))
    } else {
        defaultCount = quotientFloored;
        residualEnergyProduce = 0;
        residualEnergyConsume = 0;
    }
    return {
        defaultCount,
        residualEnergyProduce,
        residualEnergyConsume,
    };
}
